<template>
    <div>
        <GroupsOfPersons />
    </div>
</template>

<script>
import GroupsOfPersons from '@/components/settings/groupsOfPersons/index.vue';

export default {
    name: 'SettingsGroupsOfPersons',
    components: {
        GroupsOfPersons,
    },
};
</script>
